@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.table-rows{
  background-color: transparent;
}
.table-rows:hover{
  background-color: rgb(247, 240, 240);
}
.pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  list-style: none;
  padding: 0px 40px;
}
.main{
  background: rgb(171, 161, 161);
  padding: 6px 16px;
  border-radius: 16px;
  }
  .main:hover{
  background: rgb(219, 210, 210);

}